/**
 * ActionGroupDefault Component
 * @module views/modules/ActionGroupDefault
 */

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import PaletteActionGroupDefault from 'web-palette/dist/components/ActionGroupDefault/ActionGroupDefault';
import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import * as paletteUtils from 'web-palette/dist/components/ActionGroupDefault/ActionGroupDefault';
import * as utils from './ActionGroupDefault';


/**
 * Represents a ActionGroup component
 *
 * @method
 * @param {ActionGroupProps} props - React properties passed from composition
 * @returns ActionGroup
 */
export const ActionGroupDefault = function( props ){
  const {
    action1,
    action2,
    isKnockoutLink,
    type,
    style
  } = props;

  if( !action1 && !action2 ){
    return null;
  }

  const variant = paletteUtils.getVariant( type );
  const formattedAction1 = paletteUtils.actionFormatter(
    { action: action1, style: variant.action1, isKnockoutLink: isKnockoutLink },
    { getButtonMarkup: utils.getButtonHtml, getLinkMarkup: utils.getLinkHtml } );
  const formattedAction2 = paletteUtils.actionFormatter(
    { action: action2, style: variant.action2, isKnockoutLink: isKnockoutLink },
    { getButtonMarkup: utils.getButtonHtml, getLinkMarkup: utils.getLinkHtml } );

  const PalActionGroup = PaletteActionGroupDefault.default || PaletteActionGroupDefault;

  return (
    <PalActionGroup
      type={ type }
      style={ style }
      externalAction1={ formattedAction1 }
      externalAction2={ formattedAction2 }
    />
  );
};

/**
 * Function for link action style
 * @function getLinkHtml
 * @param { object }  - returns an object with the appropriate HTML markup
 */
export const getLinkHtml = ( data ) => {
  const {
    action,
    actionStyle,
    derivedAriaLabel,
    fullyClickableParent,
    isKnockoutLink
  } = data;

  const isButtonStyle = ( actionStyle === 'buttonPrimary' || actionStyle === 'buttonSecondary' || actionStyle === 'buttonTertiary' );
  const derivedButtonSize = isButtonStyle ? 'compact' : 'default';

  return (
    <>
      <Link_Huge
        variant={ actionStyle }
        isKnockoutLink={ isKnockoutLink }
        fullyClickableParent={ fullyClickableParent }
        ariaLabel={ derivedAriaLabel }
        size={ derivedButtonSize }
        className='ActionGroupDefault__Link'
        children={ action?.label }
        action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with Link_Huge. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
        { ...action }
      />
    </>
  )
};

/**
 * Function for button action style
 * @function getButtonHtml
 * @param { object }  - returns an object with the appropriate HTML markup
 */
export const getButtonHtml = ( data ) => {
  const {
    action,
    actionStyle,
    derivedAriaLabel,
    fullyClickableParent
  } = data;

  const isButtonStyle = ( actionStyle === 'primary' || actionStyle === 'secondary' || actionStyle === 'tertiary' );
  const derivedButtonSize = isButtonStyle ? 'compact' : 'default';

  return (
    <>
      <Button
        variant={ actionStyle }
        fullyClickableParent={ fullyClickableParent }
        ariaLabel={ derivedAriaLabel }
        size={ derivedButtonSize }
        className='ActionGroupDefault__Button'
        action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with the web-site Button. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
        { ...action }
      />
    </>
  )
};

/**
 * Property type definitions
 * @typedef ActionGroupDefaultProps
 * @type {object}
 * @property {string} example - refactor or delete
 */
export const propTypes =  {
  /**
   * Sets the stylistic version of the action
   * - **primary:** Sets the action to a primary style
   * - **secondary:** Sets the action to a secondary style
   * - **tertiary:** Sets the action to a tertiary style
   * - **arrow:** Sets the link to a arrow style
   */
  type: PropTypes.oneOf( [
    'Primary Button/Secondary Button',
    'Primary Button/Tertiary Button',
    'Primary Button/Primary Link',
    'Secondary Button/Primary Link',
    'Primary Link/Primary Link',
    'Arrow Link'
  ] ),
  /**
   * Sets the stylistic version of the action
   * - **inline:** the actions are displayed in a row
   * - **stacked:** the actions are displayed in a column
   * - **stackedFullWidth:** the actions are displayed in a column- buttons are full width and links are centered.
   */
  style: PropTypes.oneOf( [
    'inline',
    'stackedDefault',
    'stackedFullWidth'
  ] ),
  /**
   * Sets the primary action (palette only, no business logic)
   */
  action1: PropTypes.object,
  /**
   * Sets the secondary action (palette only, no business logic)
   */
  action2: PropTypes.object,
  /**
   * Boolean if link text is white
   */
  isKnockoutLink: PropTypes.bool
};


ActionGroupDefault.propTypes = propTypes;

export default ActionGroupDefault;